<template>
  <Layout>
    <PageHeader :title="title" :breadcrumbs="breadcrumbs" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="orderList">
          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">Minters</h5>
              <div class="flex-shrink-0 gap-1">
                <button
                  class="btn btn-soft-danger me-1"
                  @click="deleteMultiple"
                >
                  <i class="ri-delete-bin-2-line"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  data-bs-toggle="modal"
                  data-bs-target="#createmintersModal"
                >
                  <i class="ri-add-line align-bottom me-1"></i> Create Minter
                </button>
                <button
                  type="button"
                  class="btn btn-success mx-2"
                  data-bs-toggle="modal"
                  data-bs-target="#resyncModal"
                >
                  Resync
                </button>
                <button
                  type="button"
                  class="btn btn-info"
                  id="export-shipping"
                  @click="getShipping"
                >
                  <div v-if="exporting" class="d-flex"><i class="mdi mdi-spin mdi-loading mx-2"></i> Exporting...</div>
                  <div v-else class="d-flex">Export Shipping</div>
                </button>
              </div>
            </div>
          </div>
          <div
            class="card-body border border-dashed border-end-0 border-start-0"
          >
            <form>
              <div class="row g-3 filters-form">
                <div class="col-xxl-5 col-sm-6">
                  <div class="search-box">
                    <input
                      type="text"
                      class="form-control search"
                      placeholder="Search by address or ENS"
                      v-model="searchQuery"
                    />
                    <i class="ri-search-line search-icon"></i>
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-2 col-sm-4">
                  <div>
                    <Multiselect
                      class="form-control"
                      v-model="activePointCount"
                      :close-on-select="true"
                      :searchable="true"
                      :options="[1, 2, 3, 4]"
                      placeholder="Select Points"
                    />
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-2 col-sm-4">
                  <button type="button" class="btn btn-primary" @click="search">
                    <i class="ri-equalizer-fill align-bottom me-1"></i> Search
                  </button>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
          </div>
          <div class="card-body pt-0">
            <div>
              <ul
                class="nav nav-tabs nav-tabs-custom nav-success mb-3"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active All py-3"
                    data-bs-toggle="tab"
                    id="All"
                    href="#home1"
                    role="tab"
                    aria-selected="true"
                  >
                    <i class="ri-team-line me-1 align-bottom"></i> All minters
                    <span class="badge bg-info align-middle ms-1">{{
                      mintersList.length
                    }}</span>
                  </a>
                </li>
              </ul>

              <div class="table-responsive table-card mb-1">
                <table class="table table-nowrap align-middle" id="orderTable">
                  <thead class="text-muted table-light">
                    <tr class="text-uppercase">
                      <th scope="col" style="width: 25px">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="checkAll"
                            value="option"
                          />
                        </div>
                      </th>
                      <th class="sort" data-sort="wallet">Wallet</th>
                      <th class="sort" data-sort="alias">Alias</th>
                      <th class="sort" data-sort="tier">Tier</th>
                      <th class="sort" data-sort="tier">Wallet Limit</th>
                      <th class="sort" data-sort="enabled">Authorize</th>
                      <th class="sort" data-sort="points">Points</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody
                    class="list form-check-all"
                    v-for="(record, index) of resultQuery"
                    :key="index"
                  >
                    <tr>
                      <th scope="row">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="chk_child"
                            value="option1"
                          />
                        </div>
                      </th>
                      <td class="wallet">
                        <router-link to="#" class="fw-medium link-primary"
                          >{{ record.wallet }}
                        </router-link>
                      </td>
                      <td class="alias">{{ record.alias }}</td>
                      <td class="tier">{{ record.tier }}</td>
                      <td class="limit">{{ record.limit }}</td>
                      <td class="authorize">
                        <div class="form-check form-switch form-switch-sm">
                          <input
                            class="form-check-input code-switcher"
                            type="checkbox"
                            :checked="record.authorize"
                            @change="(event) => mintersEnable(event, record)"
                          />
                        </div>
                      </td>
                      <td class="points">
                        {{ (record.profile && record.profile.points) || 0 }}
                      </td>
                      <td>
                        <ul class="list-inline hstack gap-2 mb-0">
                          <li
                            class="list-inline-item edit"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Edit"
                          >
                            <a
                              class="text-primary d-inline-block edit-item-btn"
                              data-bs-toggle="modal"
                              href="#EditModal"
                              @click="editMember(record)"
                            >
                              <i class="ri-pencil-fill fs-16"></i>
                            </a>
                          </li>
                          <li
                            class="list-inline-item"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Remove"
                          >
                            <!-- <a
                              class="text-danger d-inline-block remove-item-btn"
                              @click="deletedata(record)"
                            >
                              <i class="ri-delete-bin-5-fill fs-16"></i>
                            </a> -->
                          </li>
                        </ul>
                      </td>
                      <!-- </div> -->
                    </tr>
                  </tbody>
                </table>
                <div class="noresult" style="display: none">
                  <div class="text-center">
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">Sorry! No Result Found</h5>
                    <p class="text-muted">
                      We've searched more than 150+ minters We did not find any
                      minters for you search.
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-3">
                <div class="pagination-wrap hstack gap-2">
                  <a
                    class="page-item pagination-prev disabled"
                    href="#"
                    v-if="page != 1"
                    @click="page--"
                  >
                    Previous
                  </a>
                  <ul class="pagination listjs-pagination mb-0">
                    <li
                      v-for="(pageNumber, index) in pages.slice(
                        page - 1,
                        page + 5
                      )"
                      :key="index"
                      @click="page = pageNumber"
                      :class="{
                        active: pageNumber == page,
                        disabled: pageNumber == '...',
                      }"
                    >
                      <a class="page" href="#">{{ pageNumber }}</a>
                    </li>
                  </ul>
                  <a
                    class="page-item pagination-next"
                    href="#"
                    @click="page++"
                    v-if="page < pages.length"
                  >
                    Next
                  </a>
                </div>
              </div>
            </div>
            <div
              class="modal fade"
              id="createmintersModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Add Minter
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="close-modal"
                    ></button>
                  </div>
                  <form action="#" id="addform">
                    <div class="modal-body">
                      <div class="mb-3" id="modal-id">
                        <label for="wallet" class="form-label"
                          >Wallet Address</label
                        >
                        <input
                          type="text"
                          id="wallet"
                          class="form-control"
                          placeholder="Wallet Address or ENS name"
                          required
                          maxlength="42"
                        />
                      </div>

                      <div class="mb-3">
                        <label for="wallet" class="form-label">Alias</label>
                        <input
                          type="text"
                          id="alias"
                          class="form-control"
                          placeholder="Account Name"
                          maxlength="42"
                        />
                      </div>

                      <div class="mb-3">
                        <label for="tier" class="form-label">Tier</label>
                        <input
                          type="number"
                          id="tier"
                          class="form-control"
                          placeholder="Tier"
                          required
                          maxlength="5"
                        />
                      </div>

                      <div class="mb-3">
                        <label for="limit" class="form-label">Limit</label>
                        <input
                          type="number"
                          id="limit"
                          class="form-control"
                          placeholder="Limit"
                          required
                          maxlength="5"
                        />
                      </div>

                      <div class="mb-3">
                        <label for="authorize" class="form-label"
                          >Enable wallet after creation</label
                        >
                        <div class="form-check form-switch form-switch-sm">
                          <input
                            class="form-check-input code-switcher"
                            type="checkbox"
                            id="authorize"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          class="btn btn-light"
                          data-bs-dismiss="modal"
                          id="closemodal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="btn btn-success"
                          id="add-btn"
                          @click="createMinter"
                        >
                          Add Minter
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- resync modal -->
            <div
              class="modal fade"
              id="resyncModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Resync wallets
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="close-modal-resync"
                    ></button>
                  </div>
                  <form action="#" id="resyncForm">
                    <div class="modal-body">
                      <div class="mb-3" id="modal-id">
                        <label for="resync" class="form-label">
                          Wallets json
                        </label>
                        <textarea
                          id="resyncText"
                          class="form-control"
                          placeholder="Wallets json"
                          required
                        />
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          class="btn btn-light"
                          data-bs-dismiss="modal"
                          id="closemodal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="btn btn-success"
                          id="add-btn"
                          @click="resync"
                        >
                          Resync
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="EditModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabels"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Update Minter
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="close-modal"
                    ></button>
                  </div>
                  <form action="#" id="editform">
                    <div class="modal-body">
                      <div class="mb-3" id="modal-id">
                        <label for="edit-wallet" class="form-label"
                          >Wallet Address</label
                        >
                        <input
                          type="text"
                          id="edit-wallet"
                          class="form-control"
                          placeholder="Wallet Address or ENS name"
                          required
                          disabled
                          maxlength="42"
                        />
                      </div>

                      <div class="mb-3">
                        <label for="edit-alias" class="form-label">Alias</label>
                        <input
                          type="text"
                          id="edit-alias"
                          class="form-control"
                          placeholder="Custom name or ENS name"
                          required
                          maxlength="42"
                        />
                      </div>

                      <div class="mb-3">
                        <label for="edit-tier" class="form-label">Tier</label>
                        <input
                          type="number"
                          id="edit-tier"
                          class="form-control"
                          placeholder="Wallet Address or ENS name"
                          required
                          maxlength="42"
                        />
                      </div>

                      <div class="mb-3">
                        <label for="edit-limit" class="form-label"
                          >Wallet Limit</label
                        >
                        <input
                          type="number"
                          id="edit-limit"
                          class="form-control"
                          placeholder="Wallet Limit"
                          required
                          maxlength="42"
                        />
                      </div>

                      <div class="mb-3">
                        <label for="edit-authorize" class="form-label"
                          >Enable wallet after creation</label
                        >
                        <div class="form-check form-switch form-switch-sm">
                          <input
                            class="form-check-input code-switcher"
                            type="checkbox"
                            id="edit-authorize"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          class="btn btn-light"
                          data-bs-dismiss="modal"
                          id="edtclosemodal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="btn btn-success"
                          id="add-btn"
                          @click="updateMinter"
                        >
                          Update Minter
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <!-- Modal -->
            <div
              class="modal fade flip"
              id="deleteOrder"
              tabindex="-1"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-body p-5 text-center">
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="90"
                      :width="90"
                    />
                    <div class="mt-4 text-center">
                      <h4>You are about to delete a order ?</h4>
                      <p class="text-muted fs-15 mb-4">
                        Deleting your order will remove all of your information
                        from our database.
                      </p>
                      <div class="hstack gap-2 justify-content-center remove">
                        <button
                          class="btn btn-link link-success fw-medium text-decoration-none"
                          data-bs-dismiss="modal"
                        >
                          <i class="ri-close-line me-1 align-middle"></i> Close
                        </button>
                        <button class="btn btn-danger" id="delete-record">
                          Yes, Delete It
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end modal -->
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import Swal from "sweetalert2";

import Layout from "@/layouts/main.vue";
import appConfig from "@/../app.config";
import PageHeader from "@/components/page-header";
// import axios from "axios";
import animationData from "@/components/widgets/Lootie/animations/search.json";
import Lottie from "@/components/widgets/Lootie";

import { mintersApi } from "@/lib/api";
import { compactShippingInfo } from "@/lib/utils";

export default {
  page: {
    title: "minters",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "minters",
      breadcrumbs: [],
      page: 1,
      perPage: 100,
      pages: [],
      activePointCount: null,
      listCategory: "all",
      searchQuery: "",
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "d M, Y",
        mode: "range",
      },
      defaultOptions: {
        animationData: animationData,
      },
      minters: [],
      exporting: false
    };
  },
  components: {
    Layout,
    PageHeader,
    lottie: Lottie,
    Multiselect,
  },
  computed: {
    mintersList() {
      return this.paginate(this.minters);
    },
    resultQuery() {
      if (this.searchQuery.trim().length) {
        const search = this.searchQuery.toLowerCase();
        console.log(this.mintersList);
        return this.mintersList.filter((data) => {
          return (
            data?.wallet?.toLowerCase().includes(search) ||
            data?.alias?.toLowerCase().includes(search)
          );
        });
      } else {
        return this.mintersList;
      }
    },
  },
  watch: {},
  created() {
    this.setPages();
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  beforeMount() {
    mintersApi.list().then((response) => {
      const { success, result } = response;
      if (success) {
        result.Items.forEach((row) => {
          this.minters.push(row);
        });
        this.postProcessItems();
      } else {
        console.log(result);
      }
    });
  },
  mounted() {
    var checkAll = document.getElementById("checkAll");
    if (checkAll) {
      checkAll.onclick = function () {
        var checkboxes = document.querySelectorAll(
          '.form-check-all input[type="checkbox"]'
        );
        if (checkAll.checked == true) {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = true;
            checkbox.closest("tr").classList.add("table-active");
          });
        } else {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = false;
            checkbox.closest("tr").classList.remove("table-active");
          });
        }
      };
    }
  },
  methods: {
    setPages() {
      let numberOfPages = Math.ceil(this.minters.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(minters) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return minters.slice(from, to);
    },
    createMinter() {
      const wallet = document.getElementById("wallet").value;
      const alias = document.getElementById("alias").value;
      const limit = document.getElementById("limit").value;
      const tier = document.getElementById("tier").value;
      const authorize = document.getElementById("authorize").checked;
      if (wallet != null && authorize != null) {
        const newMinter = {
          wallet,
          tier,
          authorize,
          alias,
          limit,
        };
        mintersApi
          .register(newMinter)
          .then((response) => {
            const { success, result } = response;
            if (success) {
              delete newMinter.password;
              this.minters.push(newMinter);
              this.postProcessItems();
              document.getElementById("closemodal").click();
              document.getElementById("addform").reset();
            } else {
              console.log(result);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    updateMinter() {
      const wallet = document.getElementById("edit-wallet").value;
      const alias = document.getElementById("edit-alias").value;
      const tier = document.getElementById("edit-tier").value;
      const limit = document.getElementById("edit-limit").value;
      const authorize = document.getElementById("edit-authorize").checked;
      if (wallet != null && authorize != null) {
        const newMinter = {
          wallet,
          authorize,
          alias,
          limit,
          tier,
        };
        const idx = this.minters.findIndex((m) => m.wallet === wallet);
        mintersApi
          .update(newMinter)
          .then((response) => {
            const { success, result } = response;
            if (success) {
              this.minters[idx] = newMinter;
              this.postProcessItems();
              document.getElementById("edtclosemodal").click();
              document.getElementById("editform").reset();
            } else {
              console.log(result);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    editMember(data) {
      let index = this.minters.findIndex((o) => o.wallet === data.wallet);
      document.getElementById("edit-wallet").value = this.minters[index].wallet;
      document.getElementById("edit-alias").value =
        this.minters[index].alias || "";
      document.getElementById("edit-tier").value =
        this.minters[index].tier || "";

      document.getElementById("edit-limit").value =
        this.minters[index].limit || "";

      console.log(this.minters[index]);
      document.getElementById("edit-authorize").checked =
        this.minters[index].authorize;
    },
    postProcessItems() {},
    deletedata(event) {
      console.log(event);
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        confirmButtonColor: "#34c38f",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(event);
          this.minters.splice(
            this.minters.indexOf((m) => m.wallet === event.wallet),
            1
          );
          mintersApi.delete([event.wallet]);

          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },
    deleteMultiple() {
      mintersApi.delete();
    },
    search() {
      return this.resultQuery;
    },
    extractDate(datetime) {
      if (!datetime) {
        return null;
      }
      const parts = datetime && datetime.split(" ");
      return parts[0];
    },
    extractTime(datetime) {
      if (!datetime) {
        return null;
      }
      const parts = datetime && datetime.split(" ");
      return parts[1];
    },
    mintersEnable(event, member) {
      mintersApi
        .authorize(member.wallet, event.target.checked)
        .then((response) => {
          const { success, result } = response;
          if (!success) {
            console.log(result);
          } else {
            member.enabled = event.target.checked;
          }
        });
    },
    async getShipping() {
      this.exporting = true
      const data = await mintersApi.exportShipping()
      if (data && data.success) {
        let csvContent = 'Full name,Phone,Email,Street,City,Zip,State,TokenId,Wallet\n'
        data.result.Items.forEach(entry => {
          csvContent += compactShippingInfo(entry)
        })
        const blob = new Blob([csvContent], { type: 'text/csv' })
        const dlink = document.createElement('a')
        dlink.download = 'Shipping Info ' + new Date().toLocaleDateString('en-us').replaceAll('/', '-')
        dlink.href = window.URL.createObjectURL(blob)
        dlink.onclick = function() {
          const that = this;
          setTimeout(function() {
            window.URL.revokeObjectURL(that.href);
          }, 100);
        };
        dlink.click()
        dlink.remove()
      } else {
        alert(`There was an error retrieving shipping addresses ${data.result}`)
      }
      this.exporting = false
    },
    async resync() {
      let value;
      try {
        value = JSON.parse(document.getElementById("resyncText").value);
      } catch (e) {
        return;
      }
      if (Object.keys(value).length) {
        await mintersApi.import(value).then((resp) => {
          console.log(resp);
          if (resp.success) {
            document.getElementById("close-modal-resync").click();
            document.getElementById("resyncForm").reset();
            mintersApi.list().then((response) => {
              const { success, result } = response;
              if (success) {
                result.Items.forEach((row) => {
                  this.minters.push(row);
                });
                this.postProcessItems();
              } else {
                console.log(result);
              }
            });
          }
        });
      }
    },
  },
};
</script>
